.portfolio__item {
  border-radius: 20px;

  margin: 10px;
  padding: 30px;
  box-shadow: 0px 0px 10px #9e9e9e;
}

.c-img {
  border-radius: 20px;
  width: 300;
  height: 300;
}

.button {
  margin: 10px;
}
