


.profile-picture {
  border-radius: 100%;
  width: 300;
  height: 300px;
  display: block;
  
}

.about-title{
    text-align: center;
}

.info{
    display: block;
    text-align: center;
    border-radius: 30px;
    box-shadow: 2px 2px 5px #b9b9b9;
    padding: 20px;
}

@media (max-width: 576px) {
  .profile-picture {
    width: 150px;
    height: 150px;
  }
}


