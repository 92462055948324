.software-image {
  border-radius: 20%;

  /* 
    margin: 20px;
    display: block; */
}

.first-card {
  width: 50%;
  display: block;
  margin: 0px;
  padding: 10px;
}

.shadow-card {
  box-shadow: 0px 0px 0px #7e7e7e;
  border-radius: 30px;
  padding: 25px;
  margin: 1px;
}
